// @flow

import { USER_ACTION_TYPE, USER_STATUS } from "../constants/userConstants"
import { API_TOKEN, SHOP_LOGIN_ACCOUNT, ORG_LOGIN_ACCOUNT, LL_LOGIN_ACCOUNT, LOGIN_USER_TYPE, LOGIN_USER_TYPE_SHOP, LOGIN_USER_TYPE_ORG, LOGIN_USER_TYPE_LL } from "../constants/Localstorage"
import { UserState, UserStateZero, UserAction, UserPayload, Account } from "../interfaces/User"

const initialState = UserStateZero

export function userReducer(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case USER_ACTION_TYPE.REQUEST: return { ...state, status: USER_STATUS.IS_INITIAL_STATE }
    case USER_ACTION_TYPE.RECEIVE_SUCCESS: return receiveSuccess(state, action)
    case USER_ACTION_TYPE.RECEIVE_FAILED: return receiveFailed(state, action)
    case USER_ACTION_TYPE.RECEIVE_SERVER_ERROR: return serverError(state, action)
    case USER_ACTION_TYPE.LOGOUT: return logout(state)
    case USER_ACTION_TYPE.SHOP_LOGIN_SUCCESS: return shopLoginSuccess(state, action)
    case USER_ACTION_TYPE.SHOP_LOGIN_FAILED: return shopLoginFailed(state, action)
    case USER_ACTION_TYPE.SHOP_SERVER_ERROR: return serverError(state, action)
    case USER_ACTION_TYPE.ORG_LOGIN_SUCCESS: return orgLoginSuccess(state, action)
    case USER_ACTION_TYPE.ORG_LOGIN_FAILED: return orgLoginFailed(state, action)
    case USER_ACTION_TYPE.ORG_LOGIN_LOCKED: return orgLoginLocked(state, action)
    case USER_ACTION_TYPE.ORG_SERVER_ERROR: return serverError(state, action)
    case USER_ACTION_TYPE.LL_LOGIN_SUCCESS: return llLoginSuccess(state, action)
    case USER_ACTION_TYPE.LL_LOGIN_FAILED: return llLoginFailed(state, action)
    case USER_ACTION_TYPE.LL_SERVER_ERROR: return serverError(state, action)
    default: return state
  }
}

// 廃止予定
const receiveSuccess = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.token != null) {
      const account: Account = payload.account
      if (account != null) {
        localStorage.setItem(API_TOKEN, account.token)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_LOGGED_IN,
      user: action.payload,
    }
  }
  return state
}

const shopLoginSuccess = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    localStorage.removeItem(ORG_LOGIN_ACCOUNT)
    localStorage.removeItem(LL_LOGIN_ACCOUNT)
    localStorage.setItem(API_TOKEN, payload.token)
    localStorage.setItem(SHOP_LOGIN_ACCOUNT, payload.account)
    localStorage.setItem(LOGIN_USER_TYPE, LOGIN_USER_TYPE_SHOP)

    return {
      ...state,
      status: USER_STATUS.IS_LOGGED_IN,
      user: action.payload,
    }
  }
  return state
}

const orgLoginSuccess = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    localStorage.removeItem(SHOP_LOGIN_ACCOUNT)
    localStorage.removeItem(LL_LOGIN_ACCOUNT)
    localStorage.setItem(API_TOKEN, payload.token)
    localStorage.setItem(ORG_LOGIN_ACCOUNT, JSON.stringify(payload.account))
    localStorage.setItem(LOGIN_USER_TYPE, LOGIN_USER_TYPE_ORG)

    return {
      ...state,
      status: USER_STATUS.IS_LOGGED_IN,
      user: action.payload,
    }
  }
  return state
}

const llLoginSuccess = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    localStorage.removeItem(SHOP_LOGIN_ACCOUNT)
    localStorage.removeItem(ORG_LOGIN_ACCOUNT)
    localStorage.setItem(API_TOKEN, payload.token)
    localStorage.setItem(LL_LOGIN_ACCOUNT, JSON.stringify(payload.account))
    localStorage.setItem(LOGIN_USER_TYPE, LOGIN_USER_TYPE_LL)

    return {
      ...state,
      status: USER_STATUS.IS_LOGGED_IN,
      user: action.payload,
    }
  }
  return state
}

// 廃止予定
const receiveFailed = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_FAILED,
      user: action.payload,
    }
  }
  return state
}

const shopLoginFailed = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_FAILED,
      user: action.payload,
    }
  }
  return state
}

const orgLoginFailed = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_FAILED,
      user: action.payload,
    }
  }
  return state
}

const orgLoginLocked = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_LOCKED,
      user: action.payload,
    }
  }
  return state
}

const llLoginFailed = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Landlord = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_FAILED,
      user: action.payload,
    }
  }
  return state
}

const serverError = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_SERVER_ERROR,
      user: action.payload,
    }
  }
  return state
}


const logout = (state: UserState) => {
  localStorage.removeItem(API_TOKEN)
  return {
    ...state,
    status: USER_STATUS.IS_INITIAL_STATE,
  }
}
