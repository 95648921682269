//@flow

export const API_TOKEN = 'API_TOKEN'
export const EIGYO_CURRENT_RESPONSE = 'EIGYO_CURRENT_RESPONSE'
export const SHOP_LOGIN_ACCOUNT = "SHOP_LOGIN_ACCOUNT"
export const ORG_LOGIN_ACCOUNT = "ORG_LOGIN_ACCOUNT"
export const LL_LOGIN_ACCOUNT = "LL_LOGIN_ACCOUNT"
export const LOGIN_USER_TYPE = 'LOGIN_USER_TYPE'
export const LOGIN_USER_TYPE_SHOP = 'LOGIN_USER_TYPE_SHOP'
export const LOGIN_USER_TYPE_ORG = 'LOGIN_USER_TYPE_ORG'
export const LOGIN_USER_TYPE_LL = 'LOGIN_USER_TYPE_LL'

