//@flow

export const USER_ACTION_TYPE = {
  REQUEST : 'REQUEST',
  RECEIVE_SUCCESS : 'RECEIVE_SUCCESS',
  RECEIVE_FAILED : 'RECEIVE_FAILED',
  RECEIVE_SERVER_ERROR : 'RECEIVE_SERVER_ERROR',
  COMMON_RECEIVE_HANDLED_ERROR : 'COMMON_RECEIVE_HANDLED_ERROR',
  LOGOUT : 'LOGOUT',

  SHOP_LOGIN_SUCCESS : 'SHOP_LOGIN_SUCCESS',
  SHOP_LOGIN_FAILED : 'SHOP_LOGIN_FAILED',
  SHOP_LOGIN_LOCKED: "SHOP_LOGIN_LOCKED",
  SHOP_SERVER_ERROR : 'SHOP_SERVER_ERROR',

  ORG_LOGIN_SUCCESS: "ORG_LOGIN_SUCCESS",
  ORG_LOGIN_FAILED: "ORG_LOGIN_FAILED",
  ORG_LOGIN_LOCKED: "ORG_LOGIN_LOCKED",
  ORG_SERVER_ERROR: "ORG_SERVER_ERROR",

  LL_LOGIN_SUCCESS: "LL_LOGIN_SUCCESS",
  LL_LOGIN_FAILED: "LL_LOGIN_FAILED",
  LL_SERVER_ERROR: "LL_SERVER_ERROR",

}

export const USER_STATUS = {
  IS_LOGGED_IN : "IS_LOGGED_IN",
  IS_FAILED : "IS_FAILED",
  IS_SERVER_ERROR : "IS_SERVER_ERROR",
  IS_INITIAL_STATE : "IS_INITIAL_STATE",
  IS_LOCKED : "IS_LOCKED",
}