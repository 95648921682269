// @flow

import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import { connect } from "react-redux";

import { API_TOKEN, LOGIN_USER_TYPE, LOGIN_USER_TYPE_ORG, LOGIN_USER_TYPE_LL } from './constants/Localstorage';
import { Loading } from './contents/Loading';

const alertedAuth = () => {
  try {
    localStorage.getItem(API_TOKEN)
    //alert(localStorage.getItem(API_TOKEN))
    // alert('ログアウトしました')
  } catch (e) {
    alert('localStorageが利用できません')
  }
  return <Redirect to={'/login'} />
}
const Auth = (props) => (localStorage.getItem(API_TOKEN) ? props.children : alertedAuth())
const ConnectedAuth = connect(state => ({ user: state.userReducer }))(Auth);

const loading = () => <Loading />

const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

const OrgLayout = Loadable({
  loader: () => import('./containers/OrgLayout'),
  loading
});

const LLLayout = Loadable({
  loader: () => import('./containers/LLLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const OLogin = Loadable({
  loader: () => import('./contents/org/Login'),
  loading
});

const LLogin = Loadable({
  loader: () => import('./contents/ll/Login'),
  loading
});

const OInitial = Loadable({
  loader: () => import('./contents/org/InitialProfileEdit'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const Welcome = Loadable({
  loader: () => import('./views/Pages/Welcome'),
  loading
});

const OrgPasswordReset = Loadable({
  loader: () => import('./contents/org/PasswordReset'),
  loading
});

const OrgAccountIdEdit = Loadable({
  loader: () => import('./contents/org/AccountIdEdit'),
  loading
});

class App extends Component<{}> {
  componentDidMount = () => {
  }
  getLayout = (type:string) => {
    if (type === LOGIN_USER_TYPE_ORG) return OrgLayout
    if (type === LOGIN_USER_TYPE_LL) return LLLayout
    return DefaultLayout
  }

  render() {
      return (
        <HashRouter>
          <Switch>
            <Route exact path="/o/initialConfig" name="Login Page" component={OInitial} />
            <Route exact path="/o/login" name="Login Page" component={OLogin} />
            <Route exact path="/l/login" name="Login Page" component={LLogin} />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route exact path="/" name="Welcome" component={Welcome} />
            <Route path="/o/pwr/:token" name="Password Reset" component={OrgPasswordReset} />
            <Route path="/o/ace/:token" name="AccountId Edit" component={OrgAccountIdEdit} />
            <ConnectedAuth>
              <Route path="/" name="Home" component={this.getLayout(localStorage.getItem(LOGIN_USER_TYPE))} />
            </ConnectedAuth>
          </Switch>
        </HashRouter>
      );
  }
}

export default connect(
  state => ({ user: state.userReducer })
)(App);
