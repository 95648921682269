//@flow

import { USER_STATUS } from "../constants/userConstants"

export interface Tenpo {
  actorInTime: number, //timestamp
  actorOutTime: number, //timestamp
  close: number, //timestamp
  code: string,
  contractId: string,
  coursType: string,
  deleted: boolean,
  extensionDetermineTiming: 0,
  id: string,
  name: string,
  open: number, //timestamp
  serviceTimeRounding: number,
  setserviceBaseDuration: number,
  setserviceExtensionDuration: number,
  shortName: string,
  workTimeRounding: number
}
export const TenpoZero = {
  actorInTime: 0, //timestamp
  actorOutTime: 0, //timestamp
  close: 0, //timestamp
  code: "",
  contractId: "",
  coursType: "",
  deleted: false,
  extensionDetermineTiming: 0,
  id: "",
  name: "",
  open: 0, //timestamp
  serviceTimeRounding: 0,
  setserviceBaseDuration: 0,
  setserviceExtensionDuration: 0,
  shortName: "",
  workTimeRounding: 0
}

export interface Account {
  account: string,
  admin: boolean,
  createdAt: number, //timestamp
  enabled: boolean,
  id: string,
  name: string,
  updatedAt: number //timestamp
}
export const AccountZero = {
  account: "",
  admin: false,
  createdAt: 0, //timestamp
  enabled: false,
  id: "",
  name: "",
  updatedAt: 0 //timestamp
}


export interface Shop {
  id: String,
  organizationId: String,
  店舗名: String,
  郵便番号: String,
  都道府県: String,
  住所: String,
  電話番号: String,
  クーポン表示事業者名: String,
  クーポン表示郵便番号: String,
  クーポン表示電話番号: String,
  クーポン表示店舗画像: String,
  担当者名: String,
  担当者連絡先電話番号: String,
  担当者メールアドレス: String,
  緯度: number,
  経度: number,
}

export const ShopZero: Shop = {
  id: "",
  organizationId: "",
  店舗名: "",
  郵便番号: "",
  都道府県: "",
  住所: "",
  電話番号: "",
  クーポン表示事業者名: "",
  クーポン表示郵便番号: "",
  クーポン表示電話番号: "",
  クーポン表示店舗画像: "",
  担当者名: "",
  担当者連絡先電話番号: "",
  担当者メールアドレス: "",
  緯度: 0,
  経度: 0,
}

export interface ShopAuthenticateResponse {
  result: string,
  cause?: string,
  shop?: Shop,
}
export const ShopAuthenticateResponseZero = ({
  result: "",
  cause: "",
  shop: ShopZero,
})

export interface UserState {
  status: string,
  user: ShopAuthenticateResponse
}

export interface UserAction {
  type: string,
  payload?: ShopAuthenticateResponse
}

export const UserStateZero = {
  status: USER_STATUS.IS_INITIAL_STATE,
  user: ShopAuthenticateResponseZero
}

export interface UserPayload {
  result: string,
  cause?: string,
  account?: any,
}
export const UserPayloadZero = ({
  result: "",
  cause: "",
  account: (AccountZero: Account),
} : UserPayload)
