//@flow

import { combineReducers } from 'redux';

import { Action } from "../interfaces/Action"
import { userReducer } from './userReducers';

const reducers = {
  userReducer,
}

export default combineReducers<Object, Action>(reducers);